import { Dispatch } from "./reducer";
import {
  loginUser,
  logoutUser,
  currentUser,
  updateUser,
  createUser,
  completeUser,
  createUserCoupon,
  validateDiscountCode,
  addSecondaryUsers,
} from "../../lib/authRequests";
import { countries } from "countries-list";
import { UserExtended } from "./reducer";
import { getCheckoutSessionDetails } from "../../lib/stripeRequest";

export const logout = async (dispatch: Dispatch) => {
  clearAuth(dispatch);
  return;
};

export const login = async (dispatch: Dispatch, email: string, pw: string) => {
  dispatch({
    type: "loadingAuth",
  });

  try {
    const response = await loginUser(email, pw);
    dispatch({
      type: "setAuthUser",
      payload: {
        ...response.data,
      },
    });
    return response;
  } catch (err) {
    dispatch({
      type: "setAuthError",
      payload: err,
    });
    throw err;
  }
};

export const loadCurrentUser = async (dispatch: Dispatch) => {
  dispatch({
    type: "loadingAuth",
  });

  try {
    const response = await currentUser();
    return dispatch({
      type: "setAuthUser",
      payload: {
        ...response.data,
      },
    });
  } catch (err) {
    dispatch({
      type: "setAuthError",
      payload: err,
    });
  }
};

export const clearAuth = async (dispatch: Dispatch) => {
  try {
    const response = await logoutUser();
    return dispatch({
      type: "clearAuth",
    });
  } catch (err) {
    return dispatch({
      type: "setAuthError",
      payload: err,
    });
  }
};

//save the current path before signup redirect
export const saveCurrentPath = (dispatch: Dispatch, currentPath: string) => {
  return dispatch({
    type: "saveCurrentPath",
    payload: currentPath,
  });
};

export const updateUserInfo = async (
  dispatch: Dispatch,
  user: UserExtended
) => {
  dispatch({
    type: "loadingAuth",
  });

  try {
    if (user.password !== "" && user.password_confirmation !== "") {
      user = {
        email: user.email,
        password: user.password,
        password_confirmation: user.password_confirmation,
        first_name: user.first_name,
        last_name: user.last_name,
        user_type: user.user_type,
        business_name: user.business_name,
        business_url: user.business_url,
        address: user.address,
        address2: user.address2,
        city: user.city,
        state: user.state,
        zip: user.zip,
        country: user.country,
        subscription_name: user.subscription_name,
        age: user.age,
        role: user.role,
        admin: user.admin,
        gender: user.gender,
      };
    } else {
      user = {
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        user_type: user.user_type,
        business_name: user.business_name,
        business_url: user.business_url,
        address: user.address,
        address2: user.address2,
        city: user.city,
        state: user.state,
        zip: user.zip,
        country: user.country,
        subscription_name: user.subscription_name,
        age: user.age,
        role: user.role,
        admin: user.admin,
        gender: user.gender,
      };
    }

    const response = await updateUser(user);
    if (response.status === 200) {
      dispatch({
        type: "updateUserInfo",
        payload: response.data,
      });
    }
  } catch (err) {
    dispatch({
      type: "setAuthError",
      payload: err,
    });
    throw err;
  }
};

export const createUserFirstInfo = async (user: UserExtended, couponCode?: string) => {
  try {
    user = {
      email: user.email,
      password: user.password,
      password_confirmation: user.password_confirmation,
      first_name: user.first_name,
      last_name: user.last_name,
      user_type: user.user_type,
      business_name: user.business_name,
      business_url: user.business_url,
      address: user.address,
      address2: user.address2,
      city: user.city,
      state: user.state,
      zip: user.zip,
      country: user.country,
      subscription_name: user.subscription_name,
      age: user.age,
      role: user.role,
      admin: user.admin,
      gender: user.gender,
      coupon_code: couponCode ? couponCode : null,
      regular_updates: user.regular_updates,
      share_information: user.share_information,
    };

    const response = await createUser(user);
    if (response.status === 200) {
      return response;
    }
  } catch (err) {
    throw err;
  }
};

export const completeUserInfo = async (
  dispatch: Dispatch,
  session_id: string | string[]
) => {
  try {
    dispatch({
      type: "loadingAuth",
    });
    const { data: checkout } = await getCheckoutSessionDetails(session_id as string)

    const country2Alpha = checkout.customer_details?.address?.country;
    const user = {
      address: checkout.customer_details?.address?.line1,
      address2: checkout.customer_details?.address?.line2,
      city: checkout.customer_details?.address?.city,
      state: checkout.customer_details?.address?.state,
      zip: checkout.customer_details?.address?.postal_code,
      country: countries[country2Alpha].name,
      email: checkout.customer_details?.email,
      customer_id: checkout.customer,
      subscription_id: checkout.subscription
    };

    const response = await completeUser(user);
    if (response.status === 200) {
      dispatch({
        type: "setAuthUser",
        payload: response.data,
      });
    }
    return response;
  } catch (err) {
    dispatch({
      type: "setAuthError",
      payload: err,
    });
    throw err;
  }
};

export const createStripeCoupon = async (session_id: string | string[]) => {
  const { data: checkout } = await getCheckoutSessionDetails(session_id as string);
  const paymentIntentId = checkout.payment_intent;
  const metadata = checkout.metadata;
  const giftInfo = JSON.parse(metadata.gift_info);
  const priceId = giftInfo.price_id;
  const giftSubscription = giftInfo.gift_subscription;

  const body = {
    ...giftSubscription,
    chargeId: paymentIntentId,
    priceId,
  };
  try {
    const response = await createUserCoupon(body);
    return response;
  } catch (err){
    throw err;
  }

};

export const applyCoupon = async (couponCode: string) => {
  const response = await validateDiscountCode({
    discount_code: couponCode,
  });
  return response;
}

export const createSecondaryUsers = async (
  users: { first_name: string; last_name: string; email: string }[],
  dispatch: Dispatch
) => {
  try{
    const response = await addSecondaryUsers(users);
    if (response.status === 200) {
      dispatch({
        type: "updateUserInfo",
        payload: response.data,
      });
    }
  } catch (err) {
    dispatch({
      type: "setAuthError",
      payload: err,
    });
    throw err;
  }
};
