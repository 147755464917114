export type Action =
  | { type: "setArticles"; payload: any }
  | { type: "setArticlesInfo"; payload: any }
  | { type: "setVinousTableArticles"; payload: any }
  | { type: "setCellarFavoritesArticles"; payload: any }
  | { type: "setLatestArticles"; payload: any }
  | { type: "setSearchArticles"; payload: any }
  | { type: "setArticlesError"; payload: any }
  | { type: "setActiveArticle"; payload?: any }
  | { type: "setActiveArticleProducers"; payload?: any }
  | { type: "setActiveArticleRelatedArticles"; payload?: any }
  | { type: "loadingArticles"; payload?: any }
  | { type: "loadingArticleInfo"; payload?: any }
  | { type: any; payload?: any };

export type Dispatch = (action: Action) => void;

export type ArticlesState = {
  articleSearchLoading: boolean;
  articleInfoLoading: boolean;
  articles?: Article[];
  vinousTableArticles?: Article[];
  cellarFavoritesArticles?: Article[];
  latestArticles?: Article[];
  authors?: string[];
  categories?: string[];
  error?: string;
  search: SearchArticlesState;
  activeArticle?: ActiveArticleState;
};

export type ActiveArticleState = {
  article: Article;
  rawContent: string;
  producers: Producer[];
  relatedArticles: RelatedArticles;
};

export type RelatedArticles = {
  [year: string]: Article[];
}

export type SearchArticlesState = {
  articles?: Article[];
  q?: string;
  category?: string;
  author?: string;
  total_articles: number;
};

export type IArticlesContext = {
  state: ArticlesState;
  dispatch: Dispatch;
};

export type Article = {
  id: number;
  author: string;
  content: string;
  country: string;
  date: string;
  featured?: boolean;
  hard_paywall?: boolean;
  homepage?: boolean;
  image_url?: string;
  keywords: string[];
  order_date: string;
  pdf_content_type?: string;
  pdf_file_name?: string;
  pdf_file_size?: number;
  pdf_updated_at?: string;
  preview: string;
  producers_count: number;
  published: boolean;
  region: string;
  sent_notification: boolean;
  slug: string;
  title: string;
  extracted_title: string;
};

export type Producer = {
  id: number;
  producer_name: string;
  region_2?: string;
  country: string;
  slug: string;
  region_3?: string;
  importer: string;
};

export const articlesReducerInitialState = {
  articleSearchLoading: false,
  articleInfoLoading: false,
  articles: undefined,
  authors: undefined,
  categories: undefined,
  error: undefined,
  vinousTableArticles: undefined,
  cellarFavoritesArticles: undefined,
  latestArticles: undefined,
  search: undefined,
  activeArticle: undefined
};

export const articlesReducer = (state: ArticlesState, action: Action) => {
  switch (action.type) {
    case "loadingArticles": {
      return {
        ...state,
        articlesSearchloading: true,
      };
    }
    case "loadingArticleInfo": {
      return { 
        ...state,
        articleInfoLoading: true,
      };
    }
    case "setArticles": {
      return {
        ...state,
        articles: action.payload.articles,
        error: undefined,
        articleSearchLoading: false,
      };
    }
    case "setArticlesInfo": {
      return {
        ...state,
        error: undefined,
        articleInfoLoading: false,
        categories: action.payload.categories,
        authors: action.payload.authors
      };
    }
    case "setVinousTableArticles": {
      return {
        ...state,
        error: undefined,
        articleSearchLoading: false,
        vinousTableArticles: action.payload.articles
      };
    }
    case "setCellarFavoritesArticles": {
      return {
        ...state,
        error: undefined,
        articleSearchLoading: false,
        cellarFavoritesArticles: action.payload.articles
      };
    }
    case "setLatestArticles": {
      return {
        ...state,
        error: undefined,
        articleSearchLoading: false,
        latestArticles: action.payload.articles
      };
    }
    case "setSearchArticles": {
      return {
        ...state,
        error: undefined,
        articleSearchLoading: false,
        search: {
          ...state.search,
          articles: [...action.payload.articles.map(a => a._source)],
          total_articles: action.payload.total_articles
        } 
      };
    }
    case "setActiveArticle": {
      return {
        ...state,
        error: undefined,
        articleSearchLoading: false,
        activeArticle: {
          ...state.activeArticle,
          article: action.payload.article,
          rawContent: action.payload.rawContent,
          producers: undefined,
          relatedArticles: undefined
        } 
      };
    }
    case "setActiveArticleProducers": {
      return {
        ...state,
        error: undefined,
        articleSearchLoading: false,
        activeArticle: {
          ...state.activeArticle,
          producers: action.payload
        } 
      };
    }
    case "setActiveArticleRelatedArticles": {
      return {
        ...state,
        error: undefined,
        articleSearchLoading: false,
        activeArticle: {
          ...state.activeArticle,
          relatedArticles: action.payload
        } 
      };
    }
    case "setArticlesError": {
      return {
        ...state,
        error: action.payload,
        articleSearchLoading: false,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};
