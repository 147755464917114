import { Dispatch } from "./reducer";
import {
  getMultimedia,
  getHomepageMultimedia,
  getMultimediaBySearch,
} from "../../lib/multimediaRequests";

type MultimediaParams = {
  term?: string;
  category?: string;
  per_page?: number;
  page?: number;
};

export const loadMultimedia = async(dispatch: Dispatch, params: MultimediaParams ) => {
  dispatch({
    type: "loadingMultimedia",
  });

  const { term, category, page, per_page } = params;

  try {
    const response = term 
      ? await getMultimediaBySearch(term, category, page, per_page) 
      : await getMultimedia(category);

    return dispatch({
      type: "setMultimedia",
      payload: {
        ...response,
      },
    });
  } catch (err) {
    return dispatch({
      type: "setMultimediaError",
      payload: err,
    });
  }
};

export const loadHomepageMultimedia = async(dispatch: Dispatch) => {
  dispatch({
    type: "loadingMultimedia",
  });

  try {
    const response = await getHomepageMultimedia();

    return dispatch({
      type: "setHomepageMultimedia",
      payload: {
        ...response,
      },
    });
  } catch (err) {
    return dispatch({
      type: "setMultimediaError",
      payload: err,
    });
  }
};
