import React from "react";
import { VinousProvider } from "..";
import { 
  searchArticles, 
  loadArticlesInformation, 
  loadVinousTableArticles,
  loadLatestArticles,
  loadCellarFavoritesArticles,
  loadActiveArticle,
  loadActiveArticleProducers,
  loadActiveArticleRelatedArticles
} from "./actions";
import { IArticlesContext, articlesReducerInitialState, articlesReducer } from "./reducer";

const ArticlesContext = React.createContext<IArticlesContext>({
  state: {
    articleSearchLoading: false,
    articleInfoLoading: false,
    articles: undefined,
    authors: undefined,
    categories: undefined,
    vinousTableArticles: undefined,
    cellarFavoritesArticles: undefined,
    latestArticles: undefined,
    search: undefined
  },
  dispatch: () => {},
});


const ArticlesProvider: VinousProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    articlesReducer,
    articlesReducerInitialState
  );
  const articlesValue = {
    state,
    dispatch,
  };

  return (
    <ArticlesContext.Provider value={articlesValue}>{children}</ArticlesContext.Provider>
  );
};

const useArticlesContext = () => {
  const context = React.useContext(ArticlesContext);

  if (!context) {
    throw new Error("useAuth must be used within AuthProvider");
  }

  return context;
};

export { 
  ArticlesProvider, 
  useArticlesContext, 
  searchArticles, 
  loadArticlesInformation,
  loadVinousTableArticles,
  loadLatestArticles,
  loadCellarFavoritesArticles,
  loadActiveArticle,
  loadActiveArticleProducers,
  loadActiveArticleRelatedArticles
};
