import React from "react";

const config = {
  success: {
    primaryColor: "bg-success",
    secondaryColor: "green",
    label: "Success!",
  },
  info: {
    primaryColor: "bg-info",
    secondaryColor: "grey",
    label: "Info!",
  },
  error: {
    primaryColor: "bg-error",
    secondaryColor: "red",
    label: "Error!",
  },
  warn: {
    primaryColor: "bg-warn",
    secondaryColor: "orange",
    label: "Warning!",
  },
};

const Toast = ({ type, message, id, title }) => {
  return (
    <div
      id={id}
      className={`toast-message-container z-50 min-w-300 bg-red m-7 rounded ${config[type].primaryColor} animate-TostSlideIn`}
    >
      <div
        id="toast-message"
        className="toast-message flex-1	p-4	rounded-sm	w-full text-white	"
      >
        <div className="title font-bold	">{title || config[type].label}</div>
        <div className="message">{message}</div>
      </div>
    </div>
  );
};

export default Toast;
