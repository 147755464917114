import { Dispatch } from "./reducer";
import {getInfo} from "../../lib/articleRequests";

export const loadAuthors = async(dispatch: Dispatch) => {
    dispatch({
      type: "loadingFilterOptions",
    });
  
    try {
      const response = await getInfo();
      return dispatch({
        type: "setAuthors",
        payload: {
        ...response.data.authors
        },
      });
    } catch (err) {
      return dispatch({
        type: "setEventsError",
        payload: err,
      });
    }
  };
  