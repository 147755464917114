import React, { createContext, useContext, useReducer } from "react";

const ToastStateContext = createContext({ toasts: [] });
const ToastDispatchContext = createContext(null);

const ToastReducer = (state, action) => {
  switch (action.type) {
    case "ADD_TOAST": {
      return {
        ...state,
        toasts: [...state.toasts, action.toast],
      };
    }
    case "DELETE_TOAST": {
      const updatedToasts = state.toasts.filter((e) => e.id != action.id);
      return {
        ...state,
        toasts: updatedToasts,
      };
    }
    default: {
      throw new Error("unhandled action");
    }
  }
};

export const ToastProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ToastReducer, {
    toasts: [],
  });

  return (
    <ToastStateContext.Provider value={state}>
      <ToastDispatchContext.Provider value={dispatch}>
        {children}
      </ToastDispatchContext.Provider>
    </ToastStateContext.Provider>
  );
};

export const useToast = (delay: number = 5000) => {
  const dispatch = useToastDispatchContext();

  const toast = (type, message, title?, onRemove?) => {
    const id = Math.random().toString(36).substr(2, 9);

    dispatch({
      type: "ADD_TOAST",
      toast: {
        type,
        message,
        id,
        title,
      },
    });

    setTimeout(() => {
      if (onRemove && typeof onRemove === "function") onRemove();
      dispatch({ type: "DELETE_TOAST", id, onRemove });
    }, delay);
  };

  return toast;
};

export const useToastStateContext = () => useContext(ToastStateContext);
export const useToastDispatchContext = () => useContext(ToastDispatchContext);
