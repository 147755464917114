import { Dispatch } from "./reducer";
import { getEvents } from "../../lib/eventRequests";

export const loadEvents = async(dispatch: Dispatch) => {
  dispatch({
    type: "loadingEvents",
  });

  try {
    const response = await getEvents();

    return dispatch({
      type: "setEvents",
      payload: {
        ...response,
      },
    });
  } catch (err) {
    return dispatch({
      type: "setEventsError",
      payload: err,
    });
  }
};
