import { Dispatch } from "./reducer";
import { getMapAssets, getMapAsset } from "../../lib/mapAssetRequests";

export const loadMapAssets = async (dispatch: Dispatch) => {
  dispatch({
    type: "loadingMapAssets",
  });

  try {
    const response = await getMapAssets();

    return dispatch({
      type: "setMapAssets",
      payload: [
        ...response.data,
      ],
    });
  } catch (err) {
    return dispatch({
      type: "setMapAssetsError",
      payload: err,
    });
  }
};

export const loadMapAsset = async (dispatch: Dispatch, slug: string) => {
  dispatch({
    type: "loadingMapAssets",
  });

  try {
    const response = await getMapAsset(slug);

    return dispatch({
      type: "setMapAsset",
      payload: {
        ...response.data,
      },
    });
  } catch (err) {
    return dispatch({
      type: "setMapAssetsError",
      payload: err,
    });
  }
}
