import React from "react";
import { VinousProvider } from "..";

import { 
  loadEvents
} from "./actions";
import { IEventsContext, eventReducerInitialState, eventsReducer } from "./reducer";

const EventsContext = React.createContext<IEventsContext>({
  state: {
    loading: false,
    past_events: undefined,
    upcoming_events: undefined
  },
  dispatch: () => {},
});

const EventsProvider: VinousProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    eventsReducer,
    eventReducerInitialState
  );
  const articlesValue = {
    state,
    dispatch,
  };

  return (
    <EventsContext.Provider value={articlesValue}>{children}</EventsContext.Provider>
  );
};

const useEventsContext = () => {
  const context = React.useContext(EventsContext);

  if (!context) {
    throw new Error("useAuth must be used within AuthProvider");
  }

  return context;
};

export { 
  EventsProvider, 
  useEventsContext, 
  loadEvents,
};
