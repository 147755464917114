import React from "react";
import { VinousProvider } from "..";

import { 
  loadBaseWines,
  loadWineVintages,
  loadProducers,
  loadWines
} from "./actions";
import { IWineContext, wineReducerInitialState, wineReducer } from "./reducer";

const WineContext = React.createContext<IWineContext>({
  state: {
    loading: false,
    baseWine: undefined,
    baseWines: undefined,
  },
  dispatch: () => {},
});

const WineProvider: VinousProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    wineReducer,
    wineReducerInitialState
  );
  const wineValue = {
    state,
    dispatch,
  };

  return (
    <WineContext.Provider value={wineValue}>{children}</WineContext.Provider>
  );
};

const useWineContext = () => {
  const context = React.useContext(WineContext);

  if (!context) {
    throw new Error("useAuth must be used within AuthProvider");
  }

  return context;
};

export { 
  WineProvider, 
  useWineContext, 
  loadBaseWines,
  loadWineVintages,
  loadProducers,
  loadWines
};
