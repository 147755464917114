import React from "react";
import useModal from "../hooks/useModal";
import Modal from "../components/global/Modal/Modal";

let ModalContext;
const { Provider } = (ModalContext = React.createContext({}));

let ModalProvider = (props: { children: any }) => {
  const { modal, handleModal, modalContent } = useModal();
  return (
    <Provider value={{ modal, handleModal, modalContent }}>
      { props.children }
      <Modal />
    </Provider>
  );
};

export { ModalContext, ModalProvider };