import React from "react";
import { useToastStateContext } from "../../context/toastContext";
import Toast from "./Toast";

export const ToastContainer = () => {
  const { toasts } = useToastStateContext();

  return (
    <div className="toast-container fixed w-full top-80 flex flex-col items-end	right-0 z-50">
      {toasts &&
        toasts.map((toast) => (
          <Toast
            id={toast.id}
            key={toast.id}
            type={toast.type}
            message={toast.message}
            title={toast.title}
          />
        ))}
    </div>
  );
};
