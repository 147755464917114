
export type Action =
| { type: "setAuthors"; payload: any }
| { type: any; payload?: any };

export type Dispatch = (action: Action) => void;

export type FilterState = {
    loading: boolean
    authors: String[] 
    error?: string 
}

export type IFilterContext = {
    state: FilterState;
    dispatch: Dispatch;
  };
  
export const filtersReducerInitialState = {
    loading: false,
    authors: undefined,
    error: undefined
  };
  


  export const filtersReducer = (state: FilterState , action: Action) => {
    switch (action.type) {
      case "loadingFilterOptions": {
        return {
          ...state,
          loading: true,
        };
      }
      case "setAuthors": {
        return {
          ...state,
          authors: action.payload, 
          error: undefined,
          loading: false,
        };
      }
      case "setAuthorsError": {
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`);
      }
    }
  };