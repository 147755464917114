import { Dispatch } from "./reducer";
import { getLivexPriceHistory as _getLivexPriceHistory } from "../../lib/wineRequests";

export const getLivexPriceHistory = async (dispatch: Dispatch, lwin11: string, currency: string) => {
  dispatch({
    type: "loadingLivexHistoricData",
  });

  try {
    const response = await _getLivexPriceHistory(lwin11, currency);

    return dispatch({
      type: "setLivexHistoricData",
      payload: response.data,
    });
  } catch (err) {
    return dispatch({
      type: "setVinousCellarsError",
      payload: err,
    });
  }
};
