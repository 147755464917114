import React from 'react';
import Script from 'next/script';
import { GA_TRACKING_ID } from '../../../lib/gtag';

const GoogleAnalytics = () => {
  return GA_TRACKING_ID  ? (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${GA_TRACKING_ID}');
        `}
      </Script>
    </>
  ) : null;
};

export default React.memo(GoogleAnalytics);
