import { get } from "./api";

export const getMultimedia = (category: string) => {
  return get("/api/v1/multimedia", { category });
};

export const getMultimediaFromSlug = (slug: string) => {
  return get(`api/v1/multimedia/${slug}`);
};

export const getHomepageMultimedia = () => {
  return get("/api/v2/multimedia/home_page");
};

export const getMultimediaBySearch = (
  term: string,
  category: string,
  page?: number,
  per_page?: number
) => {
  return get("/api/v2/multimedia/search", { term, category, page, per_page });
};
