export type Action =
  | { type: "setEvents"; payload: any }
  | { type: any; payload?: any };

export type Dispatch = (action: Action) => void;

export type EventsState = {
  loading: boolean;
  upcoming_events?: Event[];
  past_events?: Event[];
  error?: string;
};

export type IEventsContext = {
  state: EventsState;
  dispatch: Dispatch;
};

export type Event = {
  id: number;
  coming_soon_message: string,
  created_at: Date,
  date: string,
  description: string,
  email_confirmation_text: string,
  end_date: string,
  homepage: boolean,
  image_url: string,
  location: string,
  preview: string,
  published: boolean,
  slug: string,
  sortable_date: string,
  ticketable: boolean,
  ticket_types: any[],
  title: string,
  updated_at: Date
};

export const eventReducerInitialState = {
  loading: false,
  past_events: undefined,
  upcoming_events: undefined,
  error: undefined,
};

export const eventsReducer = (state: EventsState, action: Action) => {
  switch (action.type) {
    case "loadingEvents": {
      return {
        ...state,
        loading: true,
      };
    }
    case "setEvents": {
      return {
        ...state,
        upcoming_events: action.payload.data.upcoming,
        past_events: action.payload.data.past,
        error: undefined,
        loading: false,
      };
    }
    case "setEventsError": {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};