export type Action =
  | { type: "setMultimedia"; payload: any }
  | { type: "setHomepageMultimedia"; payload: any }
  | { type: any; payload?: any };

export type Dispatch = (action: Action) => void;

export type MultimediaState = {
  loading: boolean;
  multimedia?: Multimedia[];
  homepageMultimedia?: Multimedia[];
  categories?: MultimediaCategory[];
  error?: string;
  total?: number;
};

export type IMultimediaContext = {
  state: MultimediaState;
  dispatch: Dispatch;
};

export type MultimediaCategory = {
  id: number;
  name: string;
  visible: boolean;
  created_at: Date;
  updated_at: Date;
}

export type Multimedia = {
  id: number;
  title: string;
  caption: string;
  video_id: string;
  subtitle: string;
  created_at: Date;
  updated_at: Date;
  category: string;
  thumbnail_url: string;
  homepage: boolean;
  multimedia_category_id: number,
  date: string;
  slug: string;
  video_source: string;
  manual_thumbnail_override_file_name: string;
  manual_thumbnail_override_content_type: string;
  manual_thumbnail_override_file_size: number;
  manual_thumbnail_override_updated_at: Date;
};

export const multimediaReducerInitialState = {
  loading: false,
  categories: undefined,
  multimedia: undefined,
  homepageMultimedia: undefined,
  error: undefined,
  total: 0,
};

export const multimediaReducer = (state: MultimediaState, action: Action) => {
  switch (action.type) {
    case "loadingMultimedia": {
      return {
        ...state,
        loading: true,
      };
    }
    case "setMultimedia": {
      return {
        ...state,
        categories: action.payload.data.categories,
        multimedia: action.payload.data.multimedia,
        total: action.payload.data.total,
        error: undefined,
        loading: false,
      };
    }
    case "setHomepageMultimedia": {
      return {
        ...state,
        categories: action.payload.data.categories,
        homepageMultimedia: action.payload.data.multimedia,
        error: undefined,
        loading: false,
      };
    }
    case "setMultimediaError": {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};