import React from 'react';
import GoogleAnalytics from './GoogleAnalytics';
import FacebookEvents from './FacebookEvents';
import useAnalytics from '../../../hooks/useAnalytics';

const Analytics = () => {
  const _analyticsHook = useAnalytics();

  return (
    <>
      <GoogleAnalytics />
      <FacebookEvents />
    </>
  )
};

export default Analytics;
