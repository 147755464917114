export type Action =
  | { type: "setAuth"; payload: any }
  | { type: "setAuthError"; payload: any }
  | { type: "loadingAuth"; payload?: any }
  | {type: "saveCurrentPath"; payload?: any}
  | { type: any; payload?: any };

export type Dispatch = (action: Action) => void;

export type AuthState = {
  loading: boolean;
  subscription?: UserSubscription;
  user?: User;
  error?: string;
  message?: string;
  initialLoad: boolean;
  currentPath?: string;
};

export type IAuthContext = {
  state: AuthState;
  dispatch: Dispatch;
};

export interface User  {
  email: string
  first_name: string
  last_name: string
  address: string
  address2: string
  city: string
  state: string
  zip: string
  business_name: string
  business_url: string
  user_type: string
  country: string
  subscription_name: string
  admin: boolean
  gender: string
  age: string
  role?: string
  coupon_code?: string;
  regular_updates?: boolean,
  share_information?: boolean,
  is_user_pro?: boolean,
  primary_user?: boolean,
  secondary_user?: boolean,
  is_on_free_trial?: boolean,
  unused_secondary_users?: number,
  subscribed?: boolean,
  expires_at?: string,
  subscription_expired?: boolean,
  primary_user_data?: {
    email: string,
    stripe_plan_name: string,
  },
  secondary_users?: Array<User>,
  delectable_id?: string,
};

export interface UserExtended extends User {
  password?: string
  password_confirmation?: string
  referrer?: string
}

export interface UserSubscription {
  plan_name: string,
  stripe_id: string,
  free_for_iwc_migration: boolean,
  stripe_subscription: any
}

interface Recipient {
  recipientEmail: string,
  recipientFirstName: string,
  recipientLastName: string,
}
export interface GiftSubscription {
  recipient: Array<Recipient>,
  customerEmail: string,
  customerFirstName: string,
  customerLastName: string,
  personalMessage?: string,
  comments?: string,
}

export interface GiftSubscriptionBody extends GiftSubscription {
  planId: string;
  chargeId: string;
}

export const authReducerInitialState = {
  loading: false,
  user: undefined,
  subscription: undefined,
  error: undefined,
  message: undefined,
  initialLoad: false,
  currentPath: ""
};

export const authReducer = (state: AuthState, action: Action) => {
  switch (action.type) {
    case "loadingAuth": {
      return {
        ...state,
        message: undefined,
        loading: true,
      };
    }
    case "setAuthUser": {
      return {
        ...state,
        user: action.payload.user,
        subscription: action.payload.subscription,
        error: undefined,
        loading: false,
        initialLoad: true,
      };
    }
    case "updateUserInfo":{
      return {
        ...state,
        user: action.payload.user,
        subscription: action.payload.subscription,
        error: undefined,
        loading: false,
        initialLoad: true,
      };
    }
    case "setAuthError": {
      return {
        ...state,
        error: action.payload,
        message: undefined,
        loading: false,
        initialLoad: true,
      };
    }
    case "clearAuth": {
      return {
        ...authReducerInitialState,
        initialLoad: true,
      };
    }
    case "saveCurrentPath":{
      return {
        ...state,
        currentPath: action.payload
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};
