export type Action =
  | { type: "setMapAssets"; payload: any }
  | { type: "setMapAsset"; payload: any}
  | { type: "loadingMapAssets", payload?: any }
  | { type: "setMapAssetsError", payload: any }
  | { type: any, payload: any};

export type Dispatch = (action: Action) => void;

export type MapAssetState = {
  loading: boolean;
  mapAssets: { [slug: string]: MapAsset };
  error?: string;
};

export type IMapAssetContext = {
  state: MapAssetState;
  dispatch: Dispatch;
};

export type MapAsset = {
  description: string;
  id: number;
  map_svg_url: string;
  slug: string;
  title: string;
  order: Date;
  link: string;
  button_label: string;
};

export const mapAssetReducerInitialState = {
  loading: false,
  mapAssets: {},
  error: undefined,
};

export const mapAssetReducer = (state: MapAssetState, action: Action) => {
  switch (action.type) {
    case "loadingMapAssets": {
      return {
        ...state,
        loading: true,
      };
    }
    case "setMapAssets": {
      const orderedMapAssets = [...action.payload].filter(m => m.order).sort((l, r) => {
        return l.order < r.order ? 0 : -1;
      });
      const unorderedMapAssets = [...action.payload].filter(m => !m.order);
      
      const responseMapAssetsBySlug = [...orderedMapAssets, ...unorderedMapAssets].reduce((agg, mapAsset) => {
        agg[mapAsset.slug] = { ...mapAsset };
        return agg;
      }, {});

      return {
        ...state,
        mapAssets: {...state.mapAssets, ...responseMapAssetsBySlug },
        error: undefined,
        loading: false,
      };
    }
    case "setMapAsset": {
      const mapAsset = action.payload;
      return {
        ...state,
        mapAssets: {...state.mapAssets, [mapAsset.slug]: mapAsset },
        error: undefined,
        loading: false,
      };
    }
    case "setMapAssetsError": {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};
