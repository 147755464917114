import { Dispatch, Article } from "./reducer";
import {
  getInfo,
  getArticles,
  getVinousTableArticles,
  getLatestArticles,
  ArticleParams,
  getCellarFavoritesArticles,
  getArticleFromSlug,
  getArticleProducers,
  getArticleRelatedArticles,
  searchArticles as searchArticlesRequest,
  SearchParams
} from "../../lib/articleRequests";

export const loadArticlesInformation = async(dispatch: Dispatch) => {
  dispatch({
    type: "loadingArticleInfo",
  });

  try {
    const response = await getInfo();
    return dispatch({
      type: "setArticlesInfo",
      payload: {
        ...response.data,
      },
    });
  } catch (err) {
    return dispatch({
      type: "setArticlesError",
      payload: err,
    });
  }
};

export const searchArticles = async(dispatch: Dispatch, params?: SearchParams) => {
  dispatch({
    type: "loadingArticles",
  });

  try {
    const response = await searchArticlesRequest(params);
    return dispatch({
      type: "setSearchArticles",
      payload: {
        ...response.data,
      },
    });
  } catch (err) {
    return dispatch({
      type: "setArticlesError",
      payload: err,
    });
  }
};

export const loadLatestArticles = async(dispatch: Dispatch) => {
  dispatch({
    type: "loadingArticles",
  });

  try {
    const response = await getLatestArticles();
    return dispatch({
      type: "setLatestArticles",
      payload: {
        articles: response.data,
      },
    });
  } catch (err) {
    return dispatch({
      type: "setArticlesError",
      payload: err,
    });
  }
};

export const loadVinousTableArticles = async(dispatch: Dispatch) => {
  dispatch({
    type: "loadingArticles",
  });

  try {
    const response = await getVinousTableArticles();
    return dispatch({
      type: "setVinousTableArticles",
      payload: {
        articles: response.data,
      },
    });
  } catch (err) {
    return dispatch({
      type: "setArticlesError",
      payload: err,
    });
  }
};

export const loadCellarFavoritesArticles = async(dispatch: Dispatch) => {
  dispatch({
    type: "loadingArticles",
  });

  try {
    const response = await getCellarFavoritesArticles();
    return dispatch({
      type: "setCellarFavoritesArticles",
      payload: {
        articles: response.data,
      },
    });
  } catch (err) {
    return dispatch({
      type: "setArticlesError",
      payload: err,
    });
  }
};

export const loadActiveArticle = async(dispatch: Dispatch, slug: string) => {
  dispatch({
    type: "loadingArticles",
  });

  try {
    const response = await getArticleFromSlug(slug);
    return dispatch({
      type: "setActiveArticle",
      payload: {
        article: response.data.article,
        rawContent: response.data.raw_content,
      },
    });
  } catch (err) {
    return dispatch({
      type: "setArticlesError",
      payload: err,
    });
  }
};

export const loadActiveArticleProducers = async(dispatch: Dispatch, articleId: string) => {
  dispatch({
    type: "loadingArticles",
  });

  try {
    const response = await getArticleProducers(articleId);
    return dispatch({
      type: "setActiveArticleProducers",
      payload: response.data,
    });
  } catch (err) {
    return dispatch({
      type: "setArticlesError",
      payload: err,
    });
  }
};

export const loadActiveArticleRelatedArticles = async(dispatch: Dispatch, articleId: string) => {
  dispatch({
    type: "loadingArticles",
  });

  try {
    const response = await getArticleRelatedArticles(articleId);
    return dispatch({
      type: "setActiveArticleRelatedArticles",
      payload: response.data,
    });
  } catch (err) {
    return dispatch({
      type: "setArticlesError",
      payload: err,
    });
  }
};
