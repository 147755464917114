export type Action =
  | { type: "loadingLivexHistoricData", payload?: any }
  | { type: "setLivexHistoricData", payload: any }
  | { type: "setVinousCellarsError", payload: any }
  | { type: any, payload: any};

export type Dispatch = (action: Action) => void;

export type LivexHistoricData = {
  [lwin11: string]: LivexHistoricDataPoint[];
};

export type VinousCellarsState = {
  loading: boolean;
  livexHistoricData: LivexHistoricData;
  error?: string;
};

export type IVinousCellarsContext = {
  state: VinousCellarsState;
  dispatch: Dispatch;
};

export type LivexHistoricDataPoint = {
  lwin11: string;
  value: string;
  date_string: string;
};

export const vinousCellarReducerInitialState = {
  loading: false,
  livexHistoricData: {},
  error: undefined,
};

export const vinousCellarsReducer = (state: VinousCellarsState, action: Action) => {
  switch (action.type) {
    case "loadingLivexHistoricData": {
      return {
        ...state,
        loading: true,
      };
    }
    case "setLivexHistoricData": {
      const { livex_price_history: livexPriceHistory, lwin11 } = action.payload;

      return {
        ...state,
        livexHistoricData: { 
          ...state.livexHistoricData, 
          [lwin11]: livexPriceHistory
        },
        error: undefined,
        loading: false,
      };
    }
    case "setVinousCellarsError": {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};
