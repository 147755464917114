import React from "react";
import { VinousProvider } from "..";

import { 
  loadAuthors
} from "./actions";
import { IFilterContext,filtersReducer, filtersReducerInitialState } from "./reducer";

const FilterContext = React.createContext<IFilterContext>({
  state: {
    loading: false,
    authors: undefined,
    error: undefined
  },
  dispatch: () => {},
});

const FiltersProvider: VinousProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    filtersReducer,
    filtersReducerInitialState
  );
  const filtersValue = {
    state,
    dispatch,
  };

  return (
    <FilterContext.Provider value={filtersValue}>{children}</FilterContext.Provider>
  );
};

const useFilterContext = () => {
  const context = React.useContext(FilterContext);

  if (!context) {
    throw new Error("useAuth must be used within AuthProvider");
  }

  return context;
};

export { 
    FiltersProvider, 
    useFilterContext, 
    loadAuthors,
};
