import { useEffect } from "react";
import { useRouter } from "next/router";
import * as fbq from "../lib/fbq";
import * as gtag from "../lib/gtag";

const useAnalytics = () => {
  const router = useRouter();

  useEffect(() => {
    fbq.pageview()

    const handleRouteChange = (url) => {
      fbq.pageview();
      gtag.pageview(url);
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])
};

export default useAnalytics;