import { loadStripe, Stripe } from "@stripe/stripe-js";
import { get, post } from "./api";
import { CheckoutSessionBody } from "../utils/stripe";


let stripePromise: Promise<Stripe> = null;

export const getStripe = () => {
    if(! stripePromise)
      stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);
    return stripePromise;
}

export const getSubscriptionCheckoutSession = (checkoutInfo: CheckoutSessionBody) => {
  return post("/api/v2/stripe/checkout_session", checkoutInfo);
}

export const getSubscriptionPaymentSession = (checkoutInfo: CheckoutSessionBody) => {
  return post("/api/v2/stripe/checkout_session/gift", checkoutInfo);
}

export const getFreeTrialSubscriptionSession = (checkoutInfo: CheckoutSessionBody) => {
  return post("/api/v2/stripe/checkout_session/free_trial", checkoutInfo);
}

export const getCheckoutSessionDetails = (sessionId: string) => {
  return get(`/api/v2/stripe/checkout_session/${sessionId}`);
}

export const getBillingCustomerPortal = (stripeCustomerId: string) => {
  return get(`/api/v2/stripe/customer_billing_portal`, { stripe_id: stripeCustomerId });
}

export const getPendingInvoiceUrl = () => {
  return get("/api/v2/stripe/pending_invoice_url");
}
