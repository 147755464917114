import { get, post, deleteReq, put } from "./api";
import {GiftSubscriptionBody, UserExtended} from "../context/authContext/reducer"
export const loginUser = (email: string, password: string) => {
  return post("/api/v2/sessions/new", { email, password });
};

export const currentUser = () => {
  const cacheHeader = 'no-cache';
  return get("/api/v2/sessions/current", null, cacheHeader);
};

export const logoutUser = () => {
  return deleteReq("/api/v2/sessions/new", null);
};

export const updateUser = (user: UserExtended) =>{
  return put("/api/v2/user/edit-user", user);
}

export const createUser = (user: UserExtended) => {
  return post("/api/v2/user/create-user", user);
}

export const completeUser = (userInfo: any) => {
  return put("/api/v2/user/complete-user", userInfo);
}

export const createUserCoupon = (giftSubscriptionInfo: GiftSubscriptionBody) => {
  return post("/api/v2/user/create-coupon", giftSubscriptionInfo);
}

export const validateDiscountCode = (body: { discount_code: string} ) => {
  return post("/api/v2/user/validate-coupon", body);
}

export const addSecondaryUsers = (users: {first_name: string, last_name: string, email: string}[]) => {
  return put("/api/v2/user/add-secondary-users", {secondary_users: users});
}
