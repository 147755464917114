import { get, post } from "./api";

export const getInfo = () => {
  return get("/api/v2/articles/info");
};

export type SearchParams = {
  search_param?: string, 
  author?: string, 
  category?: string, 
  sort_by?: string, 
  sort_dir?: string,
  page_number?: number,
  per_page?: number
}

export type ArticleParams = {
  featured?: boolean,
  require_producer?: boolean,
  category?: string, 
  api_format?: string,
  homepage?: boolean
}

export const searchArticles = (params?: SearchParams) => {
  const { search_param, author, category, sort_by, sort_dir, page_number, per_page } = params || {}; 

  let params_formatted = [];
  if (search_param) params_formatted.push(`term=${search_param}`);
  if (author) params_formatted.push(`filters[author]=${author}`);
  if (category) params_formatted.push(`filters[category]=${encodeURI(category)}`);
  if (sort_by) params_formatted.push(`order=${sort_by}`);
  if (sort_dir) params_formatted.push(`order_type=${sort_dir}`);
  if (page_number || per_page) params_formatted.push(`api_format=paginated`);
  if (page_number) params_formatted.push(`page=${page_number}`);
  if (per_page) params_formatted.push(`per_page=${per_page}`);
  

  const url_query_formatted = params_formatted.length > 0 ? `?${params_formatted.join('&')}` : "";
  return get(`/api/v2/articles${url_query_formatted}`);
};

export const getArticles = (params?: ArticleParams) => {
  return get('/api/v1/articles', params);
};

export const getLatestArticles = () => {
  return getArticles({ homepage: true });
};

export const getVinousTableArticles = () => {
  return getArticles({
    category: "Vinous-Table"
  });
};

export const getCellarFavoritesArticles = () => {
  return getArticles({
    category: "Cellar-Favorites"
  });
};

export const getArticle = (id: string) => {
  return get(`api/v1/articles/${id}`);
};

export const getArticleProducers = (id: string) => {
  return get(`api/v2/article/producers`, { id });
};

export const getArticleProducerWines = (articleId: string, producerId: string) => {
  return get(`api/v1/articles/${articleId}/producers/${producerId}/wines`);
};

export const getArticleFromSlug = (slug: string) => {
  // timestamp required to prevent cached article loads
  return get(`api/v2/article`, { slug, timestamp: `${new Date().getTime()}` });
};

export const getArticleRelatedArticles = (id: string) => {
  return get(`api/v2/article/related_articles`, { id });
};

export const getArticlesForSelect = (page: number, per_page: number) => {
  return get(`api/v2/articles/for_select`, { page, per_page });
};

export const getWinesFromArticle = (articleId: number, params?: any) => {
  return get(`api/v2/wines/article/${articleId}`, params);
};
