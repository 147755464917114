import React from "react";
import { VinousProvider } from "..";

import { 
  loadMapAsset,
  loadMapAssets,
} from "./actions";
import { IMapAssetContext, mapAssetReducerInitialState, mapAssetReducer } from "./reducer";

const MapAssetContext = React.createContext<IMapAssetContext>({
  state: {
    loading: false,
    mapAssets: undefined,
  },
  dispatch: () => {},
});

const MapAssetsProvider: VinousProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    mapAssetReducer,
    mapAssetReducerInitialState
  );

  return (
    <MapAssetContext.Provider value={{ state, dispatch }}>{children}</MapAssetContext.Provider>
  );
};

const useMapAssetsContext = () => {
  const context = React.useContext(MapAssetContext);

  if (!context) {
    throw new Error("useMapAssets must be used within MapAssetsProvider");
  }

  return context;
};

export { 
  MapAssetsProvider,
  useMapAssetsContext,
  loadMapAssets,
  loadMapAsset
};
