import { Dispatch } from "./reducer";
import {
  baseWineSearch, getBaseWine, producerSearch, wineVintageSearch, getWines
} from "../../lib/wineRequests";

export type SearchBaseWinesRequest = {
  term: string;
  filters?: any;
  vintage?: number;
  order?: string;
  order_type?: string;
  page?: number;
  per_page?: number;
};

export const loadBaseWine = async(slug: string, dispatch: Dispatch) => {
  dispatch({
    type: "loadingWine",
  });

  try {
    const response = await getBaseWine(slug);

    return dispatch({
      type: "setBaseWine",
      payload: {
        ...response,
      },
    });
  } catch (err) {
    return dispatch({
      type: "setWineError",
      payload: err,
    });
  }
};

export const loadWines = async(request: any, dispatch: Dispatch) => {
  dispatch({
    type: "loadingWine",
  });

  try {
    const response = await getWines(request);

    return dispatch({
      type: "setWines",
      payload: {
        ...response,
      },
    });
  } catch (err) {
    return dispatch({
      type: "setWineError",
      payload: err,
    });
  }
};

export const loadWineVintages = async(request: any, dispatch: Dispatch) => {
  dispatch({
    type: "loadingWine",
  });

  try {
    const wineVintageResponse = await wineVintageSearch(request);

    if(wineVintageResponse.data === null) {
      return dispatch({
        type: "setWineError",
        payload: "Please search for a different wine or producer."
      })
    } else {
      return dispatch({
        type: "setWineVintages",
        payload: {
          ...wineVintageResponse,
        },
      });
    }

  } catch (err) {
    return dispatch({
      type: "setWineError",
      payload: err,
    });
  }
}

export const loadBaseWines = async(request: any, dispatch: Dispatch) => {
  dispatch({
    type: "loadingWine",
  });

  try {
    const baseWineResponse = await baseWineSearch(request);

    if(baseWineResponse.data === null) {
      return dispatch({
        type: "setWineError",
        payload: "Please search for a different wine or producer."
      })
    } else {
      return dispatch({
        type: "setBaseWines",
        payload: {
          ...baseWineResponse,
        },
      });
    }

  } catch (err) {
    return dispatch({
      type: "setWineError",
      payload: err,
    });
  }
};

export const loadProducers = async(request: any, dispatch: Dispatch) => {
  dispatch({
    type: "loadingWine",
  });

  try {
    const producerResponse = await producerSearch(request);

    if(producerResponse.data === null) {
      return dispatch({
        type: "setWineError",
        payload: "Please search for a different wine or producer."
      })
    } else {
      return dispatch({
        type: "setProducers",
        payload: {
          ...producerResponse,
        },
      });
    }

  } catch (err) {
    return dispatch({
      type: "setWineError",
      payload: err,
    });
  }
};

