import { useRouter } from "next/router";
import React from "react";

const useModal = () => {
  const [modal, setModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState("I'm the Modal Content");
  const router = useRouter();

  const handleModal = (content: any) => {
    if (modal) {
      router.back();
    }
    setModal(!modal);
    if (content) {
      setModalContent(content);
    }
  };

  return { modal, handleModal, modalContent };
};

export default useModal;
