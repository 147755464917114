import React from "react";

import { IVinousCellarsContext, vinousCellarReducerInitialState, vinousCellarsReducer } from "./reducer";
import { getLivexPriceHistory } from "./actions";
import { VinousProvider } from "..";

const VinousCellarsContext = React.createContext<IVinousCellarsContext>({
  state: {
    loading: false,
    livexHistoricData: undefined,
  },
  dispatch: () => {},
});

const VinousCellarsProvider: VinousProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    vinousCellarsReducer,
    vinousCellarReducerInitialState
  );

  return (
    <VinousCellarsContext.Provider value={{ state, dispatch }}>
      {children}
    </VinousCellarsContext.Provider>
  );
};

const useVinousCellarsContext = () => {
  const context = React.useContext(VinousCellarsContext);

  if (!context) {
    throw new Error("useVinousCellarsContext must be used within VinousCellarsProvider");
  }

  return context;
};

export { 
  VinousCellarsProvider,
  useVinousCellarsContext,
  getLivexPriceHistory,
};
