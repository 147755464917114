import React from "react";
import { VinousProvider } from "..";

import { 
  loadMultimedia,
  loadHomepageMultimedia
} from "./actions";
import { IMultimediaContext, multimediaReducerInitialState, multimediaReducer } from "./reducer";

const MultimediaContext = React.createContext<IMultimediaContext>({
  state: {
    loading: false,
    multimedia: undefined,
  },
  dispatch: () => {},
});

const MultimediaProvider: VinousProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    multimediaReducer,
    multimediaReducerInitialState
  );
  const multimediaValue = {
    state,
    dispatch,
  };

  return (
    <MultimediaContext.Provider value={multimediaValue}>{ children }</MultimediaContext.Provider>
  );
};

const useMultimediaContext = () => {
  const context = React.useContext(MultimediaContext);

  if (!context) {
    throw new Error("useAuth must be used within AuthProvider");
  }

  return context;
};

export { 
  MultimediaProvider, 
  useMultimediaContext, 
  loadMultimedia,
  loadHomepageMultimedia,
};
